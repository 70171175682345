@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
    width: 6px;
    height: 6px ;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: gray;
    height: 6px ;
  }
  
  @media (max-width: 800px) {
    ::-webkit-scrollbar {
      display: none;
    }
  
    ::-webkit-scrollbar-thumb {
      display: none;
    }
  }


.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.days {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px 10px;

}

.days div {
    border: 1px solid #d8d6de;
    border-radius: 38px;
    padding: 4px 16px;
    color: rgba(110, 107, 123, 0.75);
    cursor: pointer;
}

.days .active {
    background: rgba(0, 140, 229, 0.12);
    color: #008CE5;
    border-color: #008CE5;
}


.customInputSize input:focus {
    outline: none;
}

.customInputSize .flag-dropdown .selected-flag:hover,
.customInputSize .flag-dropdown .selected-flag.open:hover,
.customInputSize .flag-dropdown .selected-flag.open,
.customInputSize .flag-dropdown.open {
    background: #f5f5f5 !important;
}

.customInputSize .flag-dropdown {
    border: none !important;
    padding-inline: 8px !important;
    border-radius: 12px !important;
}

.customInputSize input {
    padding-left: 62px !important;
    margin: 0 !important;
    width: 100% !important;
    height: 45px !important;
    border: none !important;
    border-radius: 10px !important;
}

.pagination ul {
    display: flex !important;
    align-items: center;
}

.pagination>li {
    display: inline-block;
    padding-left: 0;
}

.pagination>li {
    list-style: none;
    margin: 0 5px;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 13px;
    line-height: 1.1;
    text-decoration: none;
    color: #4F46E5;
    /* background-color: #fff; */
    margin-left: -1px;
}

.pagination>li.active>a {
    color: #fff;
    background-color: #4F46E5;
    border-radius: 50%;
}

/* Style the active class (and buttons on mouse-over) */
.pagination>li>a:hover {
    background-color: #4F46E5;
    color: white;
    border-radius: 5px;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    border-bottom-right-radius: 4px;
    margin-right: 0;
    padding: 0px 7px !important;
    font-size: 25px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-bottom-right-radius: 4px;
    margin-right: 0;
    padding: 0px 7px !important;
    font-size: 25px;
}